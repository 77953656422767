<template>
  <section>
    <div class="content-header">
      <h2>Sexual identity and orientation</h2>
    </div>
    <div class="content-wrapper">
      <p>As you turn your attention to this section on 2SLGBTQQIA+ identities, we recognize that some of the language may be new. This is not a list of terms to be memorized. Just like identity and sexuality, language is fluid. Introducing new language into your daily speech—or refraining from using language that is offensive or hurtful to others—requires deliberate thought and practice. There needs to be both the willingness to make mistakes combined with the determination to turn these mistakes into learning opportunities to guide our own experiences with allyship.</p>
      <p>There may be moments where we are careless with our words or ignorant of their potential negative impact. None of us are immune to such missteps. Yet, we can all be deliberate in the choices we make in response to experiences of those missteps. ‘Calling it in’ is one such course of action. Posing a question, by leading with curiosity, before an accusation is another option at our disposal. We ask that you seek clarification before resorting to condemnation. We have a responsibility to one another, and our community to build space to learn from our missteps.</p>
      <p>A community of care is one that centres on curiosity, respect, education and dignity. Some of us may be unfamiliar with the continuum of identities that collectively form the 2SLGBTQQIA+ space. In the past, students have emphasized the need for us as a community to learn more about 2SLGBTQQIA+ identities, in an effort to foster a community where 2SLGBTQQIA+ members are increasingly comfortable, represented and included. </p>
      <div class="accordion">
        <accordion
          headerText="Overview"
          accordionID="collapse01"
        >
          <p>Sexuality refers to one’s relationship and expression of their attraction to others which can include their sexual attraction, romantic attraction, and other intimate attractions they have to other individuals. It is important to note that sexuality and sexual orientation are not synonymous with gender identity.</p>
          <ul>
            <li>Sexuality is fluid and one’s experience with their sexuality is dynamic and continues to evolve over the course of their lifetime. A person’s sexuality should be understood and accepted exactly as it is described by them. </li>
            <li>Everyone has a sexuality, but everyone experiences and expresses their sexuality differently even if they describe it under similar labels. </li>
            <li>Every experience and expressed orientation is valid regardless of the relationship one has with their sexuality and how closely tied it is with their personal identity. An individual does not need to “prove” their sexuality and the gender of an intimate partner does not conclude one’s sexuality alone. For example, an individual could identify as being a queer person in a cis-gendered, hetero-romantic relationship, and this does not take away or change their sexual orientation or sexuality. </li>
          </ul>
          <p class="pt-2">A recent Ivey Honours Business Administration (HBA) grad shared the following</p>
          <div class="panel">
            <p><em>"I would have liked my HBA colleagues to know that sexuality is a distinctly individual identity. Just like the idea of a 'typical' Ivey student is a myth, equally, the 'typical' gay man, 'typical' lesbian, 'typical' queer, heterosexual, bisexual, etc. doesn't exist. Sexual (and other) identities can be fluid and might mean different things to different people. Someone’s sexual identity only relates to their sexuality – not their personality. During my HBA experience, some people told me directly that they were scared and didn't know how to interact with me because they hadn’t worked/socialized with 2SLGBTQQIA+ people before. There’s nothing inherent in my, or anyone’s, sexual identity that makes them less approachable, scarier, or radically different than folks who don’t identify as 2SLGBTQQIA+.” </em></p>
          </div>
          <p class="pt-4">The 2SLGBTQQIA+ community represents individuals with a large range of sexual orientations, romantic orientations and gender identities who have historically built communities with each other to create safe spaces for sexual, romantic and gender expression. </p>
          <p>This community goes by many labels and new labels are continually created by people to help describe the complexity of each individual’s experience with sexuality and gender. To add, many people do not even assign or identify themselves with a label at all. After all, labels, like gender, are a social construct and individuals are not forced to self-identify within those labels.</p>
          <p>Even with this general definition, it is important to remember that sexuality is a social construct and is a way to help express and contextualize one’s experience with their attraction to others. Thus, the labels and definitions used to underpin these experiences will continue to evolve. </p>
        </accordion>
        <accordion
          headerText="Sexuality labels"
          accordionID="collapse02"
        >
          <p>For some people, their label is very important to their identity, whereas others may use umbrella terms to describe their sexuality but may not have or use a specific label to represents their sexuality. Further, there are varying levels of vulnerability that comes with sharing one’s sexuality. It is important that we try not to put pressure on people to use labels or constrain them to a specific definition due to the specificity of each person’s experience. This is part of the reason why many people use a variety of terms to describe their sexuality because using more than one label or perhaps even no labels does not diminish their sexuality and who they are attracted to. Additionally, some individuals may still not fully know where they are at in this journey and may be questioning their sexual identity. </p>
          <p>The following describes various sexual and romantic orientations and terms. For many, the genders they are sexually attracted to align with the genders they are romantically attracted to and thus some people choose to identify under one label to encompass both forms of attraction. But it’s important to understand the difference between sexual and romantic orientations as they can be independent characteristics. While sexual orientation is the tendency to feel sexual desire toward people of certain genders, a person may have the tendency to fall in love with certain people. We might call this romantic orientation—the desire for intimate and emotional relationships. </p>
          <p>This list is not exhaustive, but instead provides a starting point of the most frequently used labels and umbrella terms people use to describe their sexuality. As well, these definitions are not comprehensive, given the individuality of sexuality but instead offer a general introduction to each label. </p>
          <h5>Common sexual orientation labels</h5>
          <ul>
            <li class="pb-3"><strong>Queer</strong> is an umbrella term often used to describe individuals that are not heterosexual or heteroromantic and/or not cis-gendered. This use of the term is acceptable if it is also acknowledged that not every non-heterosexual, and non-heteroromantic person identifies as queer. <em>Note: Queer was originally used as a derogatory term to marginalize members of the 2SLGBTQQIA+ community but has been reclaimed by some members of the community as an empowering descriptor. It’s important to remember that this term may still be regarded as a slur to many individuals and should not be used to describe a specific person unless given permission. It is also important to be mindful and respectful of acceptable in-group and out-group terminology. For example, “queer” is acceptable to use when referring to queer communities but should be avoided when referencing a specific individual</em>. </li>
            <li class="pb-3"><strong>Lesbian</strong> refers to a woman or non-binary person who is sexually attracted to women or feminine-aligned folks. </li>
            <li class="pb-3"><strong>Gay</strong> is an umbrella term often used to describe individuals who are not heterosexual or heteroromantic. It is most commonly used as a label for a man or non-binary person who is sexually attracted to men or masculine-aligned folks. </li>
            <li class="pb-3"><strong>Bisexual</strong> refers to a sexual orientation of a person, regardless of their gender, when they are sexually attracted to more than one gender. <em>Note: Bisexuality is not meant to reinforce the gender binary, and those who identify as bisexual understand the multiplicity of gender identities that exist. Due to the connotation of duality within the prefix “bi”, some individuals have adopted the term polysexual, but bisexual remains to be a term describing the attraction to two or more gender identities</em>. </li>
            <li class="pb-3"><strong>Pansexual</strong> refers to the sexual orientation of a person, regardless of their gender, when they are sexually attracted to all people, regardless of those people’s gender. It is distinguished from bisexuality because many pansexual folks feel that gender is not a factor within their attraction to people. </li>
            <li class="pb-3"><strong>Asexual</strong> refers to a sexual orientation of a person, regardless of their gender, when they have little to no sexual attraction to other people. </li>
            <li class="pb-3"><strong>Demisexual</strong> is a sexual orientation of people who are not sexually attracted to individuals until they have established an emotional connection with another person. This emotional connection can be romantic, platonic, or any other form of relationship. </li>
            <li class="pb-3"><strong>Unlabeled</strong> is often a term used for people who do not have a word or definition they associate with their sexual orientation and choose not to define it. </li>
            <li class="pb-3"><strong>Questioning</strong> is a term used for any individual who is undergoing a process of exploring their sexual and/or romantic attraction in a way that is constructive and comfortable for them. <em>Note: Bicurious, specifically, is a term under the concept of questioning used to describe the exploration of attraction to more than one gender. Regardless of the term used, it is important to respect the continual process of sexual and romantic exploration and to be supportive of these experiences</em>. </li>
          </ul>
          <h5>Common romantic orientation labels</h5>
          <ul>
            <li><strong>Heteroromantic</strong> refers to a person who is romantically attracted to people who do not have the same gender as them. </li>
            <li><strong>Homoromantic</strong> refers to a person who is romantically attracted to people who have the same gender as them. </li>
            <li><strong>Aromantic</strong> refers to a person who has little to no romantic attraction to other people, regardless of their gender. </li>
            <li><strong>Biromantic</strong> refers to a person who is romantically attracted to more than one gender. </li>
          </ul>
          <p class="pt-3">Learn more at <a
              href="https://www.torontopflag.org/"
              target="_blank"
            >Toronto Pflag</a>, <a
              href="https://egale.ca/donate"
              target="_blank"
            >Egale Canada</a>, <a
              href="https://www.the519.org/"
              target="_blank"
            >The 519</a> and the <a
              href="http://lgbtq2stoolkit.learningcommunity.ca/"
              target="_blank"
            >LGBTQ2S Toolkit</a>. </p>
        </accordion>
        <accordion
          headerText="Tips for discussing other people’s sexuality"
          accordionID="collapse03"
        >
          <h5>Respect the personal ownership of sexuality</h5>
          <p>Every individual should have control over their sexuality, and no one is entitled to know someone else’s sexuality. The expression of sexuality is inherently personal and may be associated with distinct social, cultural, and personal histories that shape how willing an individual is in disclosing this information. If someone is comfortable sharing their sexuality and other personal details, they will most often initiate the conversation. </p>
          <h5>Create open spaces for disclosure </h5>
          <p>If asking about an individual’s relationship status, use non-gendered terms such as ‘partner’ or ‘significant other’ to actively remove heteronormative assumptions within conversations. </p>
          <p>Without putting yourself in an uncomfortable state, create visibility and space to discuss sexual and gender identities, relationships structures, and sexuality by discussing your own experience (at a level of disclosure you are comfortable with). </p>
          <h5>Carefully refer to previously disclosed labels </h5>
          <p>If someone has confided with you in the past that they hold a specific sexuality label, feel free to embrace it and clarify whether you are using the correct term to describe their sexuality!</p>
          <p><em>Note: It is always imperative to clarify what circumstances you are allowed to use someone’s label or express their sexuality. Out of personal safety and comfort, many individuals choose to only disclose their sexuality to specific trusted people or in specific spaces they feel safe in.</em></p>
          <h5>Remain non-assumptive when discussing sexuality </h5>
          <p>If you are ever unsure what exact label someone prefers, it is always helpful and supportive to do away with labels and just refer to their sexuality as the gender(s) that they are attracted to. You may also opt to practice using neutral language on a daily basis to avoid using presumptive language. Above all, always be open to new experiences and how people may choose to express their sexuality while never assuming what one’s orientation might be. Examples of Non-Label Sexuality References: “You like non-binary folks and men” or “Since you are attracted to women, ...” </p>
        </accordion>
        <accordion
          headerText="Some current prevalent issues"
          accordionID="collapse04"
        >
          <p><strong>Homophobia</strong> refers to the disapproval and prejudice against the queer community. It takes form in physical violence; hateful comments; the lack of acceptance from family members and community members; and discriminatory marriage, employment, and housing laws and practices.</p>
          <p class="pb-3">Two recent HBA grads share the impact of homophobic language on them: </p>
          <div
            class="panel"
          >
            <p><em>“I rarely attended Section events in HBA1 because I heard the f-slur thrown around and ‘gay’ being used as an insult during Ivey O-Week, I felt like I was back in 2009. I felt uncomfortable around many of my classmates after hearing the way they spoke about gender. You don’t know who’s in the room with you, and you don’t know how your words might affect them. It’s been almost 2 years since my Ivey O-Week, and it still sticks with me. We’re taught to make assumptions in the classroom, don’t make them about your classmates.”</em> </p>
            <p><em>“A clear memory I have from high school is a group of guys trying to, in their words, “find the fag” in our class. It was terrifying for me, and at the time, all I wanted was the knowledge that someone might be willing to support me in that moment. Hearing the same thing at Ivey instantly brought me back to that memory, and made me question the people I thought were my friends and future colleagues.”</em> </p>
          </div>
          <p class="pt-3">Derogatory Language is used in the form of slurs or hateful terms to subordinate queer individuals and marginalize them for their sexuality.</p>
          <ul class="pt-3">
            <li class="pb-2"><strong>Queer</strong>, <em>see “Sexuality Labels” section</em>. </li>
            <li class="pb-2">The <strong>F-slur</strong>, or the <strong>D-slur</strong> are terms used to subordinate and harm individuals who are a part of the 2SLGBTQQIA+ community. The D-slur has historically been exclusively used to target women, whereas the F-slur has been used irrespective of gender. Many individuals in the 2SLGBTQQIA+ community have chosen to reclaim these words, but due to the traumatic connotation to these words, they remain extremely offensive to many. </li>
            <li class="pb-2"><strong>Microaggressions</strong> are subtle messages and terms in discussion that are intended to be harmless in nature but often allude to queer people as being abnormal, comical, or aligned with an ill-informed stereotype of traits, and therefore, should not be used. Examples include:
              <ul>
                <li>“That’s so gay.” </li>
                <li>“No homo.” </li>
                <li>“She’s just doing it for attention.” </li>
              </ul>
            </li>
            <li class="pb-2"><strong>Rainbow-washing/Pinkwashing</strong> refers to the use of pro-2SLGBTQQIA+ messaging and claims to detract attention from negative actions an organization, government, or group of individuals are committing. Oftentimes, these messages and claims are performative and are used in substitute of genuine support to the 2SLGBTQQIA+ community (i.e., resources, funding, and inclusivity initiatives).</li>
            <li class="pb-2"><strong>Conversion therapy</strong> refers to any activity or service that aims to change an individual’s sexuality or gender identity. These programs and services aim to “cure” individuals to be cis-gendered, heterosexual, and heteroromantic people. These initiatives have incited severe emotional trauma on 2SLGBTQQIA+ people involved.
            </li>
            <li class="pb-2"><strong>Increased mental health challenges</strong> are faced by queer individuals with higher rates of mood disorders, suicide, and substance use disorders within the community compared to heterosexual and heteroromantic counterparts. Examples of barriers and negative attitudes:
              <ul>
                <li>Culturally competent care remains inaccessible to queer people who wish to receive relevant mental healthcare and sexual healthcare. </li>
                <li>Homophobia can incite emotional trauma in individuals, increasing their risk of mood disorders. </li>
                <li>Queer individuals are more likely to have a lower income which intersects with various social determinants of mental health including employment, financial stress, and access to healthcare.</li>
              </ul>
            </li>
          </ul>
        </accordion>
        <accordion
          headerText="Summary"
          accordionID="collapse05"
        >
          <p>Our hope is that, as you reflect on sexual identity and orientation, you recognize that sexual identity and orientation are not synonymous with gender identity. While reflecting on the intersections of your own and others’ identities and lived experiences, please remember that the intersections of gender identity and sexual identity and orientation are complex and complementary and not mutually exclusive. </p>
          <p>Similarly, the role you play as an ally and community member in your institution is also going to be complex and complimentary to one another. </p>
        </accordion>
        <accordion
          headerText="Supports for 2SLGBTQQIA+ folks"
          accordionID="collapse06"
        >
          <ul>
            <li><a
                href="http://www.itgetsbetter.org/"
                target="_blank"
              >It Gets Better Campaign</a> – Suicide awareness and prevention video campaign for 2SLGBTQQIA+ youth</li>
            <li><a
                href="https://www.youthline.ca/"
                target="_blank"
              >Lesbian, Gay, Bi & Trans Youthline</a> – Free peer support for youth aged 26+ (1-800-268-9688) </li>
            <li><a
                href="http://www.pflagcanada.ca/"
                target="_blank"
              >Parents, Friends of Lesbians and Gays (PFLAG)</a> – General resources for 2SLGBTQQIA+ people and their families </li>
            <li><a
                href="http://www.egale.ca/"
                target="_blank"
              >Egale Canada</a> – Advocacy and education resources and programming centered around 2SLGBTQQIA+ inclusion </li>
            <li><a
                href="https://www.the519.org/"
                target="_blank"
              >The 519</a> – Phone counselling by appointment for 2SLGBTQQIA+ people aged 18+ and housing support counselling for youth </li>
          </ul>
        </accordion>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
